import { render, staticRenderFns } from "./BchSubscriptionEdit.vue?vue&type=template&id=2fa5c128&scoped=true"
import script from "./BchSubscriptionEdit.vue?vue&type=script&lang=js"
export * from "./BchSubscriptionEdit.vue?vue&type=script&lang=js"
import style0 from "./BchSubscriptionEdit.vue?vue&type=style&index=0&id=2fa5c128&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fa5c128",
  null
  
)

export default component.exports