<template>
   <div class="Typo3SubscriptionDetails">
      <div class="name">{{ subscription.name }}</div>
      <div class="columns">
         <div class="left">
            <div class="row gap">
               <Dropdown :options="{
                  listItems: instances,
                  listItemKey: '_id',
                  listItemValueKey: 'name',
               }"
                  label="Instanz"
                  width="175px"
                  v-model="subscription.instance" />
               <Dropdown label="PHP"
                  :listItems="config.phpVersions"
                  width="75px"
                  v-model="subscription.phpVersion" />
               <Dropdown label="TYPO3"
                  :listItems="config.typo3Versions"
                  width="75px"
                  v-model="subscription.typo3Version" />
            </div>
            <Input label="Domain"
               width="410px"
               :options="{
                  before: 'https://'
               }"
               v-model="subscription.domain" />
            <div class="row gap">
               <Input label="Monat"
                  :readonly="adminReadonly"
                  width="85px"
                  :options="{
                     after: 'CHF'
                  }"
                  :value="subscription.monthly"
                  @input="subscriptionMonthly" />
               <Input label="Jahr"
                  :readonly="adminReadonly"
                  width="85px"
                  :options="{
                     after: 'CHF'
                  }"
                  :value="subscription.yearly"
                  @input="onSubscriptionYearly" />
               <DatePicker label="Seit"
                  class="noNotification"
                  format="toIsoDate"
                  v-model="subscription.since" />
            </div>
            <div class="row gap">
               <DatePicker label="Beginn"
                  :readonly="adminReadonly"
                  class="noNotification"
                  v-model="subscription.startDate"
                  format="toIsoDate" />
               <DatePicker label="Ende"
                  :readonly="adminReadonly"
                  class="noNotification"
                  format="toIsoDate"
                  v-model="subscription.endDate" />
            </div>
            <Textarea class="comment"
               placeholder="Kommentar"
               v-model="subscription.comment" />
         </div>
         <div class="right">
            <section>
               <label>DNS</label>
               <Textarea placeholder="DNS Angaben"
                  v-model="subscription.comment" />
            </section>
            <section>
               <label>Registrar</label>
               <Textarea placeholder="Registrar Angaben"
                  v-model="subscription.comment" />
            </section>
            <section>
               <label>Technisch</label>
               <Textarea placeholder="Kommentar"
                  v-model="subscription.comment" />
            </section>
            <section>
               <SubscriptionComment label="Kundeninstanz"
                  placeholder="Angaben zur Kundeninstanz"
                  :subscription="subscription" />
            </section>
         </div>
      </div>
      <div class="action">
         <Button @click="$emit('close')">Abbrechen</Button>
         <Button @click="onSave"
            class="blue">Speichern</Button>
      </div>
   </div>
</template>

<script>
import Input from '@components/Form/Input.vue';
import DatePicker from '@components/Form/DatePicker.vue';
import Dropdown from '@components/Form/Dropdown.vue';
import Textarea from '@components/Form/Textarea.vue';
import Button from '@components/Form/Button.vue';
import SubscriptionComment from '../components/SubscriptionComment.vue';

import api from '@components/Portal/api'
export default {
   name: 'Typo3SubscriptionDetails',
   components: { Input, DatePicker, Dropdown, Textarea, Button, SubscriptionComment },
   async setup(props, context) { },
   props: {
      subscription: { required: true },
      config: { required: true },
      instances: { required: true },
      mode: { required: false },
      apiUrl: { required: true },
   },
   data() {
      return {}
   },
   methods: {
      onSubscriptionYearly(value) {
         if (isNaN(value)) {
            value = null
         }
         value = Number(value)
         this.subscription.yearly = value
      },
      onSubscriptionMonthly(value) {
         if (isNaN(value)) {
            value = null
         }
         value = Number(value)
         this.subscription.monthly = value
      },
      onSave() {

      }
   },
   computed: {
      adminReadonly() {
         if (this.mode == 'admin') return false
         return true
      }
   },
}
</script>

<style scoped>
.Typo3SubscriptionDetails {}

.name {
   font-weight: 300;
   font-size: 24px;
   margin-bottom: 15px;
}

.columns {
   display: flex;
   column-gap: 25px;
   align-items: flex-start;
}

.left {
   width: 410px;
}

.right {
   display: flex;
   flex-direction: column;
   row-gap: 15px;
   width: 410px;
}

section {
   margin: 0;
   display: flex;
   flex-direction: column;
   row-gap: 5px;
}



.action {
   display: flex;
   padding-top: 25px;
   justify-content: space-around;
}

.Textarea {
   background-color: var(--activeHover);
   min-height: 45px;
   width: 100%;
   padding: 5px;
}

.Textarea.comment {
   min-height: 75px;
}
</style>