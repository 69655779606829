<template>
	<div class="Tabs"
		v-if="show">
		<div class="scrollIcon left"
			v-if="scrollIconLeftShow > 0"
			@click="scrollTo('prev')">
			<Icon icon="iconScrollLeft" />
		</div>
		<div class="tabs"
			ref="tabs"
			@scroll="onScroll">
			<div class="tabItems"
				@mousewheel="onMouseWheel"
				ref="tabItems">
				<div v-for="(tab, index) in tabItems"
					:key="index"
					class="tabItem"
					ref="tabItem"
					:title="tab.text"
					:class="{ active: isActive(tab), single: tabs.length < 2, disabled: tab.disabled }"
					:style="tab.style || {}"
					@click="onTabClick(tab)">
					<div v-if="tab.before"
						class="before">
						<component v-if="tab.before.component"
							:is="tab.before.component"
							v-bind="tab.before.props || {}" />
						<template v-else>{{ tab.before }}</template>
					</div>
					<div class="text">{{ tab.text }}</div>
					<div v-if="tab.after"
						class="after">
						<component v-if="tab.after.component"
							:is="tab.after.component"
							v-bind="tab.after.props || {}" />
						<template v-else>{{ tab.after }}</template>
					</div>
				</div>
				<div v-if="$scopedSlots.after" class="slot"><slot name="after" /></div>
			</div>
		</div>

		<div class="scrollIcon right"
			v-if="scrollIconRightShow"
			@click="scrollTo('next')">
			<Icon icon="iconScrollRight" />
		</div>
	</div>
</template>

<script>
import Icon from '@icons/Icon.vue'
import { ChevronLeft, ChevronRight } from '@icons/appFabric/icons'
export default {
	name: 'Tabs',
	props: {
		tabs: {
			required: false,
			default: [],
		},
		value: {
			required: false,
			type: Object,
		},
		activeTab: {
			required: false,
			default: 0,
		},
		routePathIndex: {
			required: false,
		},
		show: {
			required: false,
			default: true,
		},
	},
	components: { Icon },
	data() {
		return {
			scrollIconLeftShow: false,
			scrollIconRightShow: false,
			scrollSteps: 0,
			lastScrollAt: null,
			observerTabItems: null,
			observerTabs: null,
		}
	},
	static: {
		iconScrollLeft: ChevronLeft,
		iconScrollRight: ChevronRight,
	},
	methods: {
		init() {
			if (!this.mounted) return
			this.setTabFromRoute()

			if (this.show) {

				if (!this.observerTabItems) {
					setTimeout(() => {
						this.observerTabItems = new ResizeObserver(this.onScroll).observe(this.$refs.tabItems)
						this.observerTabs = new ResizeObserver(this.onScroll).observe(this.$refs.tabs)
						this.onScroll()
						this.scrollToActiveTab()
					}, 100)
				} else {
					this.onScroll()
					this.scrollToActiveTab()
				}

			} else {
				if (this.observerTabItems) {
					this.observerTabItems.disconnect()
				}
				if (this.observerTabs) {
					this.observerTabs.disconnect()
				}
				this.observerTabItems = null
				this.observerTabs = null
			}

		},
		isActive(tab) {
			//if value (v-model) is bound, use value to compare
			if (this.value) {
				return tab._id == this.value._id
			}
			//active tab == tab-Object
			if (typeof this.activeTab == 'object' && typeof this.activeTab._id != 'undefined') {
				return tab._id === this.activeTab._id
			}
			//active tab == index
			if (!isNaN(this.activeTab)) {
				return tab == this.tabItems[this.activeTab]
			}
			//active tab == text
			return tab.text == this.activeTab
		},
		onNav(tab) {
			if (tab) {
				this.routerNavigate(tab.path)
				this.scrollTo(tab)
			}
		},
		onTabClick(tab) {
			if (tab) {
				this.onNav(tab)
				this.$emit('nav', tab)
			}
			//event navigation
			this.$emit('click', tab)
		},
		routerNavigate(path) {
			if (!path) return
			if (typeof this.routePathIndex != 'number') return
			//if (!this.routePathParts[this.routePathIndex]) return
			// prepare everything before routePathIndex
			let basePath = '/'
			for (let i = 0; i < this.routePathIndex; i++) {
				basePath += `${this.routePathParts[i]}/`
			}
			let fullPath = basePath + path

			if (this.$route.path == fullPath) return
			this.$router.push(fullPath)
		},
		onScroll() {
			if (!this.$refs.tabs) {
				this.scrollIconLeftShow = false
				this.scrollIconRightShow = false
				return
			}
			let scrollLeft = this.$refs.tabs.scrollLeft
			this.scrollIconLeftShow = scrollLeft != 0
			this.scrollIconRightShow = scrollLeft < this.$refs.tabItems.clientWidth - this.$refs.tabs.clientWidth
		},
		onMouseWheel(e) {
			e.preventDefault() // disable default scrolling
			// normalize wheel delta
			let delta
			if (e.detail) {
				if (e.wheelDelta) delta = (e.wheelDelta / e.detail / 40) * (e.detail > 0 ? 1 : -1)
				// Opera
				else delta = -e.detail / 3 // Firefox
			} else delta = e.wheelDelta / 120 // IE,Safari,Chrome

			this.scrollSteps += delta
			this.lastScrollAt = new Date().getTime()

			setTimeout(() => {
				let now = new Date().getTime()
				// scroll only if at least two updates have been made in the last 100 milliseconds
				if (this.lastScrollAt && now - this.lastScrollAt <= 100) {
					this.onMousWheelScroll(this.scrollSteps)
				}
				this.scrollSteps = 0
			}, 100)
		},
		onMousWheelScroll(scrollSteps) {
			let { nextTab, previousTab, currentTab } = this.getTabScrollInfo()

			if (scrollSteps < 0 && nextTab) {
				this.scrollTo(nextTab)
			} else if (scrollSteps > 0 && previousTab) {
				this.scrollTo(previousTab)
			}
		},
		getTabScrollInfo() {
			let totalWidth = this.$refs.tabItems.clientWidth
			let viewBoxWith = this.$refs.tabs.clientWidth

			let scrollPos = this.$refs.tabs.scrollLeft

			let tabInfo = {
				perviousTab: null,
				currentTab: null,
				currentTabIndex: null,
				nextTab: null,
			}

			if (totalWidth > viewBoxWith) {
				let lastItem = this.$refs.tabItem[0]

				this.$refs.tabItem.forEach((tabItem, index) => {
					if (scrollPos == tabItem.offsetLeft) {
						tabInfo.currentTab = tabItem
						tabInfo.currentTabIndex = index
					}
					// element is after scrollPos
					else if (scrollPos > lastItem.offsetLeft && scrollPos < tabItem.offsetLeft) {
						// visibility of lastItem
						let lastItemVisbility = 1 - (scrollPos - lastItem.offsetLeft) / lastItem.getBoundingClientRect().width

						// if previous el is > halfway scrolled
						if (lastItemVisbility < 0.8) {
							tabInfo.currentTab = tabItem
							tabInfo.currentTabIndex = index
						} else {
							tabInfo.currentTab = this.$refs.tabItem[index - 1]
							tabInfo.currentTabIndex = index - 1
						}
					}
					lastItem = tabItem
				})

				tabInfo.currentTabIndex = tabInfo.currentTabIndex || 0
				tabInfo.currentTab = tabInfo.currentTab || this.$refs.tabItem[0]
				tabInfo.previousTab = this.$refs.tabItem[tabInfo.currentTabIndex - 1]
				tabInfo.nextTab = this.$refs.tabItem[tabInfo.currentTabIndex + 1]
			}

			return tabInfo
		},
		scrollTo(where) {
			// no scroll
			if (this.$refs.tabItems.clientWidth - this.$refs.tabs.clientWidth <= 0) {
				return
			}

			// scroll to element
			if (typeof where == 'object' && where._id) {
				let index = this.tabs.findIndex(item => item._id == where._id)
				where = this.$refs.tabItem[index]
			}

			if (where instanceof Element) {
				let maxScrollPos = this.$refs.tabItems.clientWidth - this.$refs.tabs.clientWidth
				let offsetLeft = Math.min(where.offsetLeft, maxScrollPos)
				offsetLeft = offsetLeft ? Math.max(offsetLeft - this.scrollIconWidth, 0) : 0 // scrollIcon width correction
				this.$refs.tabs.scrollLeft = offsetLeft
				return
			}

			if (where == 'next') {
				let maxScrollPos = this.$refs.tabItems.clientWidth - this.$refs.tabs.clientWidth
				let { nextTab } = this.getTabScrollInfo()
				let offsetLeft = nextTab.offsetLeft ? Math.max(nextTab.offsetLeft - this.scrollIconWidth, 0) : 0 // scrollIcon width correction
				this.$refs.tabs.scrollLeft = Math.min(offsetLeft, maxScrollPos)
				return
			}

			if (where == 'prev') {
				let { nextTab, previousTab, currentTab } = this.getTabScrollInfo()
				let tab = previousTab || currentTab
				let offsetLeft = tab.offsetLeft ? Math.max(tab.offsetLeft - this.scrollIconWidth, 0) : 0 // scrollIcon width correction
				this.$refs.tabs.scrollLeft = offsetLeft
				return
			}
		},
		scrollToActiveTab() {
			if (this.activeTab) {
				this.scrollTo(this.activeTab)
			}
		},
		getTabPathFromRoute() {
			if (typeof this.routePathIndex != 'number') return
			return this.routePathParts.length >= this.routePathIndex + 1 ? this.routePathParts[this.routePathIndex] : null
		},
		tabsFindByPath(path) {
			return this.tabItems.find(item => {
				if (!item.path) return false
				if (Array.isArray(item.path)) return item.path.includes(item)
				return item.path == path
			})
		},
		setTabFromRoute() {
			let path = this.getTabPathFromRoute()
			let tab = this.tabsFindByPath(path)
			// tab not found
			if (!tab || tab.show == false) {
				// if there is already an activeTab-> return
				if (this.activeTab && Object.keys(this.activeTab).length > 0) return
				//set first tab
				tab = this.tabItems.find(item => item.show !== false)
				if (!tab) return
				this.routerNavigate(tab.path)
			}

			// tab already active
			if (tab && this.activeTab._id == tab._id) return

			this.$emit('nav', tab || {})
		},
	},
	computed: {
		tabItems() {
			return this.tabs
				.map((item, index) => {
					return {
						show: typeof item.show == 'undefined' ? true : item.show,
						disabled: false,
						index: index,
						...item,
					}
				})
				.filter(item => item.show)
		},
		routePathParts() {
			return this.$route.path.split('/').filter(item => item)
		},
		tabPath() {
			if (typeof this.routePathIndex != 'number') return
			if (this.routePathParts.length > this.routePathIndex) return this.routePathParts[this.routePathIndex]
			return null
		},
		scrollIconWidth() {
			return this.$el.classList.contains('large') ? 18 : 16
		},
	},
	created() {
		this.setTabFromRoute()
	},
	mounted() {
		this.mounted = true;
		this.init()
	},
	watch: {
		show() {
			this.init()
		}
	}
}
</script>

<style scoped>
.Tabs {
	user-select: none;
}

.tabs-row {
	display: inline-flex;
	margin-bottom: 15px;
	max-width: 100%;
	position: relative;
}

.Tabs.underline {
	display: flex;
	border-bottom: 1px solid rgba(167 167 167 / 75%);
}

.tabs {
	display: inline-flex;
	overflow-x: hidden;
	max-width: 100%;
	scroll-behavior: smooth;
}

.tabItems {
	display: inline-flex;
	scroll-behavior: smooth;
	gap: 25px;
	flex-wrap: nowrap;
	position: relative;
}

.tabItem {
	margin-bottom: 0.35em;
	color: rgba(0, 0, 0, 0.75);
	position: relative;
	user-select: none;
	display: flex;
	align-items: center;
	padding: 0 3px;
	border-radius: 2px;
}

.tabItem>.text {
	font-weight: 300;
	line-height: 26px;
	letter-spacing: 0.00735em;
	color: rgba(0, 0, 0, 0.75);
	font-size: 20px;
	cursor: pointer;
	letter-spacing: 1px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;
}

.tabItem>.before {
	margin-right: 3px;
}

.tabItem>.after {
	margin-left: 3px;
}

.Tabs.underline .tabItem {
	padding: 12px 16px;
	min-width: 90px;
	color: rgba(0, 0, 0, 0.8);
	margin-bottom: 0;
}

.Tabs.large .tabItem>.text {
	font-size: 24px;
}

.Tabs.small .tabItem>.text {
	font-size: 16px;
	font-weight: 400;
}

.Tabs .tabItem.active>.text,
.Tabs.large .tabItem.active>.text,
.Tabs.small .tabItem.active>.text {
	font-weight: 500;
}

.Tabs.underline .tabItem.active {
	color: var(--accentBlue);
}

.tabItem.active:after {
	content: '';
	letter-spacing: 0px;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	border-bottom: 1px solid grey;
}

.Tabs.underline .tabItem.active::after {
	border-bottom-width: 2px;
	border-color: var(--accentBlue);
}

.scrollIcon {
	position: absolute;
	top: 0;
	z-index: 2;
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: 0.35em;
	height: 100%;
	background-color: rgba(255, 255, 255, 1);
	color: black;
	width: 16px;
}

.Tabs.small .scrollIcon {
	top: -4px;
}

.Tabs.small.underline .scrollIcon {
	top: 0;
}

.Tabs.large .scrollIcon {
	height: 29px;
	font-size: 18px;
	width: 18px;
}

.scrollIcon.left {
	left: 0;
	justify-content: flex-start;
}

.scrollIcon.right {
	right: 0;
	justify-content: flex-end;
}
</style>
