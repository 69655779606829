<template>
   <div class="Contacts">
      <h6 class="title">
         <Icon icon="iconGroup" />
         Kontakte
      </h6>
      <div class="contactsRow">
         <div class="slideOutRow">
            <div class="activeContact">
               <div v-if="activeContact"
                  class="contact">
                  <div class="type">{{ getType(activeContact.type) }}</div>
                  <div class="name"> {{ activeContact.name }}</div>
                  <div class="position">{{ activeContact.position }}</div>
                  <div class="row">
                     <a :href="`mailto:${activeContact.email}`"
                        class="email"
                        @click.stop>{{ activeContact.email }}</a>
                  </div>
                  <div class="row">
                     <a :href="`tel:${activeContact.phone}`"
                        class="phone"
                        @click.stop>{{ activeContact.phone }}</a>
                  </div>
                  <div v-if="activeContact.address"
                     class="address">
                     <div class="row street">
                        {{ activeContact.address.street }}
                     </div>
                     <div class="row location">
                        <div class="zip">{{ activeContact.address.zip }}</div>
                        <div class="city">{{ activeContact.address.city }}</div>
                     </div>
                     <div class="row">
                        <div class="country">{{ activeContact.address.country }}</div>
                     </div>
                  </div>
                  <div v-if="activeContact.billing"
                     class="billingContact">Rechnungskontakt</div>
                  <div v-if="customer.contacts.length == 1"
                     class="edit "
                     @click="onEditContact(activeContact)">
                     <Icon :icon="$static.iconEdit" />
                  </div>
                  <Textarea class="comment"
                     v-if="activeContact.comment"
                     :value="activeContact.comment" />
               </div>
            </div>
            <div v-if="customer.contacts.length > 1"
               class="contactListBtn"
               @click="toggleContactsListShow(contactsListShow)">
               <Icon icon="iconViewList" />
            </div>
            <div v-else
               class="contactListBtn add"
               @click="onEditContact({})">
               <Icon icon="iconAdd" />
            </div>
         </div>
      </div>

      <Dialog v-if="contactsListShow || editContact"
         closeBtn="true"
         transferTo="modal"
         @close="onDialogClose"
         @outsideClick="onDialogClose">
         <div v-if="contactsListShow"
            class="contactsList"
            ref="contactsList">
            <div v-for="contact in customer.contacts"
               :key="contact._id"
               class="contact">
               <div class="type">{{ getType(contact.type) }}</div>
               <div class="name"> {{ contact.name }}</div>
               <div class="position">{{ contact.position }}</div>
               <div class="row">
                  <a :href="`mailto:${contact.email}`"
                     class="email"
                     @click.stop>{{ contact.email }}</a>
               </div>
               <div class="row">
                  <a :href="`tel:${contact.phone}`"
                     class="phone"
                     @click.stop>{{ contact.phone }}</a>
               </div>

               <div v-if="contact.address"
                  class="address">
                  <div class="row street">
                     {{ contact.address.street }}
                  </div>
                  <div class="row location">
                     <div class="zip">{{ contact.address.zip }}</div>
                     <div class="city">{{ contact.address.city }}</div>
                  </div>
                  <div class="row">
                     <div class="country">{{ contact.address.country }}</div>
                  </div>
               </div>
               <div v-if="contact.billing"
                  class="billingContact">Rechnungskontakt</div>
               <div class="actions">
                  <div class="btn text"
                     @click="onEditContact(contact)">Bearbeiten</div>
                  <div class="chckBx">
                     default
                     <CheckBox class="small"
                        :value="contact.default ? true : false"
                        @input="onDefault(contact, $event)" />
                  </div>
               </div>
            </div>
            <div class="contactListBtn add "
               @click="onEditContact({})">
               <Icon :icon="$static.iconAdd" />
            </div>
         </div>
         <div v-if="editContact"
            class="editContact">
            <div class="typeRow">
               <Dropdown class="type"
                  label="Kontakttyp"
                  :listItems="contactTypes"
                  :value="editContact.type"
                  @input="onContactTypeChange" />
               <Switcher label="Rechnungskontakt"
                  :labels="{ checked: 'Ja', unchecked: 'Nein' }"
                  :width="45"
                  v-model="editContact.billing" />
            </div>
            <div class="colRow">
               <div class="col">
                  <Input label="Name"
                     v-model="editContact.name" />
                  <Input label="Position"
                     v-model="editContact.position" />
                  <Input label="Email"
                     v-model="editContact.email" />
                  <Input label="Telefon"
                     v-model="editContact.phone" />
                  <div class="editComment">
                     <label>Kommentar</label>
                     <Textarea :value="editContact.comment"
                        @input="onEditContactCommentInput"
                        @blur="onEditContactCommentBlur" />
                  </div>

               </div>
               <div class="col">
                  <Input label="Strasse"
                     v-model="editContact.address.street" />
                  <div class="row">
                     <Input class="zip"
                        label="PLZ"
                        v-model="editContact.address.zip" />
                     <Input class="city"
                        label="Ort"
                        v-model="editContact.address.city" />
                  </div>
                  <Input label="Land"
                     v-model="editContact.address.country" />
               </div>
            </div>
            <div class="actionRow">
               <Button v-if="editContact.type != 'bch'"
                  class="red"
                  @click="onEditContactDelete">Löschen</Button>
               <Button class="blue"
                  :enabled="!editContactDisabled"
                  @click="onEditContactSave">Speichern</Button>
            </div>
         </div>
      </Dialog>
   </div>
</template>

<script>
import CheckBox from '@components/Form/CheckBox.vue';
import Icon from "@icons/Icon.vue"
import { Group, Edit, Add, CaretRightSolid8, More } from "@icons/appFabric/icons"
import Dialog from '@components/Dialog/Dialog.vue';
import Input from '@components/Form/Input.vue';
import Button from '@components/Form/Button.vue';
import Dropdown from '@components/Form/Dropdown.vue';
import Switcher from '@components/Form/Switcher.vue';
import GrowShrink from '@components/Containers/GrowShrink.vue';
import utils from "@SyoLab/utils"
import { DateTime, Timestamp, Day } from '@SyoLab/date-time';
import AnimateSizeChange from '@components/Animations/AnimateSizeChange.vue';
import api from '@components/Portal/api';
import Textarea from '@components/Form/Textarea.vue';
export default {
   name: 'Contacts',
   components: { CheckBox, Icon, Dialog, Input, Button, Dropdown, Switcher, GrowShrink, AnimateSizeChange, Textarea },
   props: {
      customer: { required: true },
   },
   data() {
      return {
         editContact: null,
         contactTypes: {
            agency: 'Agentur',
            web: 'Webagentur',
            webApp: 'Webapplikationen',
            onlineMarketing: 'Online Marketing',
            technical: 'Technisch',
            bch: 'Bildung',
            digitalHR: 'Digital HR',
         },
         activeContact: null,
         contactsListShow: false
      }
   },
   static: {
      iconGroup: Group,
      iconEdit: Edit,
      iconAdd: Add,
      iconArrowRight: CaretRightSolid8,
      iconViewList: Group
   },
   methods: {
      init() {
         this.setActiveContact()
      },
      getType(type) {
         if (!type) return
         return this.contactTypes[type]
      },
      onEditContact(contact) {
         setTimeout(() => {
            this.contactsListShow = false
            this.editContact = {
               _id: utils.shortId(),
               type: undefined,
               name: null,
               position: null,
               email: null,
               phone: null,
               address: {
                  street: null,
                  zip: null,
                  city: null,
                  country: null
               },
               billing: false,
               ...utils.clone(contact)
            }
         }, 1);

      },
      onContactTypeChange(type) {
         if (type == 'billing') {
            this.editContact.billing = true
         } else if (this.editContact.type == 'billing') {
            this.editContact.billing = false
         }
         this.editContact.type = type
      },
      trim(value) {
         return value ? value.trim() : null
      },
      onEditContactSave() {
         let contact = {
            _id: this.editContact._id,
            type: this.editContact.type,
            name: this.trim(this.editContact.name),
            position: this.trim(this.editContact.position),
            email: this.trim(this.editContact.email),
            phone: this.trim(this.editContact.phone),
            billing: this.editContact.billing,
            comment: this.editContact.comment || null,
            lastUpdate: Timestamp()
         }
         if (Object.keys(this.editContact.address).filter(key => this.editContact.address[key]).length > 0) {
            contact.address = this.editContact.address
            Object.keys(contact.address).forEach(key => contact.address[key] = this.trim(contact.address[key]))
         }

         let index = this.customer.contacts.findIndex(c => c._id == this.editContact._id)
         if (index > -1) {
            this.$set(this.customer.contacts, index, contact)

         } else {
            this.customer.contacts.push(contact)
         }

         this.activeContact = contact
         this.editContact = null

         api.post('customer/contacts', {
            customer_id: this.customer._id,
            contacts: this.customer.contacts
         })

      },
      onEditContactDelete() {
         let _id = this.editContact._id
         let index = this.customer.contacts.findIndex(c => c._id == this.editContact._id)
         if (index > -1) {
            this.customer.contacts.splice(index, 1)
         }
         this.editContact = null
         this.setActiveContact()
         api.post('customer/contacts', {
            customer_id: this.customer._id,
            contacts: this.customer.contacts
         })
      },
      onEditContactCommentInput(value) {
         if (!this.editContact.comment) {
            this.$set(this.editContact, 'comment', '')
         }
         this.editContact.comment = value
      },
      onEditContactCommentBlur() {
         if (this.editContact.comment) {
            this.editContact.comment = this.editContact.comment.trim()
         }
      },
      outsideClickFn(e) {
         if (!this.contactsListShow || !this.$refs.contactsList) return
         if (!this.$refs.contactsList.contains(e.target)) {
            this.onDialogClose()
         }
      },
      toggleContactsListShow(value) {
         this.editContact = null
         setTimeout(() => {
            this.contactsListShow = !value
         }, 1);
      },
      onDialogClose() {
         this.editContact = null
         this.contactsListShow = false
      },
      onDefault(contact, value) {

         value = value ? true : false

         this.customer.contacts.forEach(c => {
            if (c._id == contact._id) {
               this.$set(c, 'default', value)
               this.activeContact = c
            } else {
               this.$set(c, 'default', !value)
            }
         })
         api.post('customer/contacts', {
            customer_id: this.customer._id,
            contacts: this.customer.contacts
         })

      },
      setActiveContact() {
         if (this.customer.contacts.length > 0) {
            if (this.customer.contacts.find(c => c.default)) {
               this.activeContact = this.customer.contacts.find(c => c.default)
            } else {
               this.activeContact = this.customer.contacts[0]
            }
         } else {
            this.activeContact = null
         }
      }
   },
   computed: {
      editContactDisabled() {
         if (!this.editContact) return true
         if (!this.editContact.type) return true
         return false
      }
   },
   watch: {
      customer() {
         this.init()
      }
   },
   created() {
      this.init()
   },
   mounted() {
      setTimeout(() => {
         // add outsideClick listener
         this.$portal.$on("documentTouchClick", this.outsideClickFn);
      }, 100);
   },
   beforeDestroy() {
      this.$portal.$off("documentTouchClick", this.outsideClickFn);
   }
}
</script>

<style scoped>
.Contacts {
   position: relative;

}

.title {
   display: flex;
   align-items: center;
   column-gap: 10px;
}


.title>.Icon {
   font-size: 18px;
}

.addContactBtn {
   margin-bottom: 5px;
}

.contactsRow {
   display: flex;
   width: 100%;
   padding-bottom: 15px;

}

.slideOutRow {
   display: flex;
   column-gap: 10px;
   min-height: 147px;
   background-color: white;
   padding: 10px 0;
   padding-right: 10px;
}


.activeContact {
   display: flex;

}

.contactListBtn {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #f9f9f9fc;
   padding: 5px;
   border-radius: 3px;
   color: #595858;
   cursor: pointer;
}

.contactListBtn.add {
   color: black;
   font-size: 16px;
   width: 35px;
   height: 100%;
   border-radius: 3px;
   background-color: var(--activeHover);
}

.contactsList .contactListBtn.add {
   width: 55px;
   height: 55px;
   background-color: var(--activeHover);
}

.contactsList {
   display: flex;
   flex-wrap: wrap;
   max-width: 100%;
   gap: 15px;
   padding-top: 55px;
}

.contact {
   background-color: #f9f9f9fc;
   padding: 15px 21px;
   display: flex;
   flex-direction: column;
   border-radius: 3px;
   position: relative;
   width: 310px;
}

.contact>div {
   text-overflow: ellipsis;
   max-width: 100%;
   overflow: hidden;
}


.colRow {
   display: flex;
   gap: 35px;
}

.edit {
   position: absolute;
   top: 5px;
   right: 5px;
   padding: 5px;
   cursor: pointer;
}

.edit:hover {
   background-color: var(--activeHover)
}

.contact .name {
   font-size: 24px;
   font-weight: 300;
   margin-bottom: 8px;
}

.contact .type {
   font-weight: 700;
   color: #616161;
   margin-bottom: 6px;
   font-size: 14px;
}

.contact .position {
   margin-bottom: 7px;
}

.contact .billingContact {
   margin-top: 5px;
   font-size: 14px;
}

.contact .actions {
   flex: 1;
   display: flex;
   align-items: flex-end;
   justify-content: space-between;
   column-gap: 20px;
   margin-bottom: -5px;
   padding-top: 15px;
}

.actions .btn,
.actions .chckBx {
   font-size: 12px;
   padding: 5px 5px;
}

.actions .chckBx {
   display: flex;
   align-items: center;
   column-gap: 3px;
   font-weight: 500;
   text-transform: uppercase;
   color: var(--accentBlue);
}

.address {
   display: flex;
   flex-direction: column;
   padding-top: 9px;
}

.address .location {
   column-gap: 5px;
}

a {
   color: #036ddd;
   text-decoration: none;
}

.editContact {
   display: flex;
   flex-direction: column;
   gap: 10px;
   padding-top: 25px;
}

.editContact .actionRow {
   display: flex;
   justify-content: space-around;
   margin-top: 20px;
}

.editContact .typeRow {
   display: flex;
   gap: 50px;
}

.editContact .row {
   display: flex;
   gap: 35px;
}

.editContact .col {
   display: flex;
   flex-direction: column;
   justify-content: start;
   gap: 10px;
   width: 350px;
}

.Input.zip {
   width: 65px;
}

.Input.city {
   flex-grow: 1;
}

.Dropdown.type {
   width: 170px;
}

.Textarea.comment {
   margin-top: 10px;
}

.editComment {
   display: flex;
   flex-direction: column;
   position: relative;

   .Textarea {
      padding: 10px 0;
   }

   &::before {
      bottom: -1px;
      content: '';
      left: 0;
      position: absolute;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      width: 100%;
      border-style: solid;
      border-width: 1px 0 0 0;
      border-color: #619cca;
      z-index: 1;
      white-space: nowrap;
   }

   :focus()::after {
      bottom: -1px;
      content: '';
      left: 0;
      position: absolute;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      width: 100%;
      transform: scaleX(0);
      border-style: solid;
      border-width: 1px 0 1px 0;
      border-color: #619cca;
   }

}
</style>