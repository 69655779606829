<template>
	<div class="Input form"
		:class="{ focus: hasFocus, readonly: settings.readonly || settings.disabled }"
		:style="componentStyle">
		<div class="activation"
			@click="onInputClick">
			<div v-if="$slots.before"
				ref="beforeSlot"
				class="before">
				<slot class="before"
					name="before"></slot>
			</div>
			<div v-if="settings.before"
				ref="beforeText"
				class="beforeText">{{ settings.before }}</div>
			<div class="autogrow"
				ref="autogrow"
				:style="autoGrowStyle">
				<input :autocomplete="settings.autocomplete"
					:name="inputName"
					:id="inputId"
					:type="inputType"
					:inputmode="inputmodeValue"
					:readonly="settings.readonly"
					:disabled="settings.disabled"
					:spellcheck="settings.spellcheck"
					:placeholder="placeholderText"
					:value="displayValue"
					:tabindex="settings.tabindex"
					:maxlength="settings.maxlength"
					:style="inputStyle"
					size="1"
					@focus="onFocus"
					@blur="onBlur"
					@input="onInput($event.target.value, true, $event)"
					@keyup.enter.stop="onEnter"
					@keyup.esc.stop="$emit('esc')"
					@keyup.down="$emit('arrowDown', formatTo(componentValue))"
					@keyup.up="$emit('arrowUp', formatTo(componentValue))"
					@keypress="onKeypress"
					ref="input" />
			</div>

			<div class="pwMask"
				v-if="type == 'password'"
				@click="pwMask = !pwMask">
				<Icon :icon="pwMask ? iconHidden : iconHide" />
			</div>
			<div ref="afterText"
				v-if="settings.after"
				class="afterText">{{ settings.after }}</div>
			<div v-if="$slots.after"
				ref="afterSlot"
				class="after">
				<slot name="after"></slot>
			</div>
			<div v-if="(settings.clearIcon || settings.clearable) && !this.settings.readonly && !this.settings.disabled"
				ref="afterClear"
				class="afterClear">
				<Icon class="clearIcon"
					:icon="iconClear"
					@click.stop="onClear" />
			</div>

		</div>
		<div class="notification"
			:class="{ error: settings.notificationIsError }">{{ settings.notification }}</div>
	</div>
</template>

<script>
import utils from '@SyoLab/utils'
// auto-width: https://css-tricks.com/auto-growing-inputs-textareas/
import Icon from '@icons/Icon.vue'
import { RedEye, Hide, ChromeClose } from '@icons/appFabric/icons.js'

export default {
	name: 'Input',
	components: { Icon },
	props: {
		label: {
			type: String,
			required: false,
			default: '',
		},
		value: {
			required: false,
			default: null,
		},
		readonly: {
			type: [String, Boolean],
			required: false,
			default: false,
		},
		disabled: {
			type: [String, Boolean],
			required: false,
			default: false,
		},
		notification: {
			type: String,
			required: false,
		},
		maxlength: {
			type: Number,
			required: false,
		},
		notificationIsError: {
			type: Boolean,
			required: false,
			default: false,
		},
		type: {
			type: String,
			required: false,
			default: 'text',
		},
		textAlign: {
			type: String,
			required: false,
			default: 'left',
		},
		spellcheck: {
			type: String,
			required: false,
			default: 'false',
		},
		autocomplete: {
			type: String,
			required: false,
			default: 'one-time-code',
		},
		options: {
			type: Object,
			required: false,
			default: function () {
				return {}
			},
		},
		placeholder: {
			type: String,
			required: false,
		},
		mask: {
			required: false,
			type: String,
		},
		maskShow: {
			required: false,
		},
		maskNumberDigits: {
			required: false,
			default: 2,
		},
		inputmode: {
			required: false,
		},
		tabindex: { required: false, default: 0 },
		clearable: { required: false }, // value can be cleared be pressing x
		clearIcon: { required: false }, // icon to show
		width: { required: false },
		minWidth: { required: false },

	},
	data() {
		return {
			hasFocus: false,
			inputData: null,
			iconHidden: RedEye,
			iconHide: Hide,
			iconClear: ChromeClose,
			pwMask: true,
			uID: utils.shortId(),
			tokens: {
				'#': { pattern: /\d/ },
				'-': { pattern: /-/ },
				X: { pattern: /[0-9a-zA-Z]/ },
				S: { pattern: /[a-zA-Z]/ },
				A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
				a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
				'!': { escape: true },
			},
			dynamicMask: null,
			isFullyMasked: false,
			separatorDecimal: '.',
			displayValue: null,
			componentValue: null,
			maxWidthCorrection: 0,
			observer: null,
		}
	},
	methods: {
		focus() {
			this.$refs.input.focus()
		},
		blur() {
			if (this.$refs.input == document.activeElement) {
				this.$refs.input.blur()
				// should be triggered by input blur
				//this.$emit('blur', this.componentValue)
			}
		},
		onBlur(event) {
			this.hasFocus = false
			this.$emit('blur', this.componentValue, event)
			setTimeout(() => {
				// reset displayValue if value is not updated
				if (this.componentValue != this.value) {
					this.onInput(this.value, false)
				}
			}, 100)

		},
		onFocus(event) {
			if (this.settings.readonly || this.settings.disabled) {
				event.preventDefault()
				return
			}

			if (this.settings.selectOnFocus && this.displayValue && this.displayValue.length > 0) {
				this.$refs.input.setSelectionRange(0, this.displayValue.length)
			}
			this.hasFocus = true
			this.$emit('focus', event.target)
		},
		onEnter(event) {
			let eventValue = event.target.value
			// if maks is set:
			if (this.settings.mask) {
				eventValue = this.maskNumberGetValue(eventValue)
			}
			this.$emit('enter', this.formatTo(eventValue))
			if (this.settings.blurOnEnter) {
				this.blur()
			}
		},
		onInputClick(event) {
			this.$emit('click', event)
		},
		onInput(value, emit = true, event) {

			let displayValue = value
			let componentValue = value

			// if maks is set:
			if (this.settings.mask) {
				if (value) {
					let { unmasked, masked, isFullyMasked } = this.maskValue(String(value))
					this.isFullyMasked = isFullyMasked

					displayValue = masked
					componentValue = this.settings.maskReturnValue == 'unmasked' ? unmasked : masked
				} else {
					displayValue = value
					componentValue = value
					this.isFullyMasked = false
				}
				this.$emit('mask', { componentValue, displayValue, isFullyMasked: this.isFullyMasked })
			}

			this.displayValue = displayValue
			this.setAutoWidthText(this.displayValue)
			if (this.componentValue != componentValue) {
				this.componentValue = componentValue
				if (emit) {
					if (this.settings.throttled) {
						this.throttledEmit(this.formatTo(this.componentValue))
					} else {

						this.emit(this.formatTo(this.componentValue))
					}
				}
			}
		},
		formatTo(value) {
			if (typeof this.settings.formatTo == 'function') {
				value = this.settings.formatTo(value)
			}
			if (typeof this.settings.formatTo == 'string') {
				if (this.settings.formatTo == 'number') {
					value = Number(value)
				}
			}
			return value
		},
		onKeypress(event) {

			// prevent adding vlaues that lead to isNaN
			if (this.settings.mask == 'number') {
				if (event.key == 'Enter') return
				let curval = event.srcElement.value

				let newchar = String.fromCharCode(event.charCode || event.keyCode)

				let curval_arr = curval.split('')
				curval_arr.splice(
					event.target.selectionStart,
					event.target.selectionEnd - event.target.selectionStart,
					newchar,
				)
				let newval = curval_arr.join('')

				try {
					// if newVal is not maskable, we prevent the keypress
					newval = this.maskNumberGetValue(newval)
				} catch (error) {
					event.preventDefault()
					return
				}


				// if (newval && newval.trim()[0] == '-') {
				// 	console.log('ret', newval)
				// 	return
				// }
				// if (newval && newval.trim()[0] == this.separatorDecimal) {
				// 	return
				// }

				if (isNaN(Number(newval))) {
					event.preventDefault()
					return
				}
			}
		},
		maskValue(value) {

			var cursorPos = this.$refs.input.selectionEnd
			let inputValue = value || this.$refs.input.value

			let masked
			if (this.settings.mask == 'number') {
				if (cursorPos == 1 && value == '-') {
					return {
						unmasked: value,
						masked: value,
					}
				}
				inputValue = this.maskNumberGetValue(inputValue)
				this.dynamicMask = this.maskNumberGetMasked(inputValue)
				masked = this.maskGetMask(inputValue, cursorPos)
				masked.unmasked = Number(this.maskNumberGetValue(masked.unmasked))
			} else {
				masked = this.maskGetMask(inputValue, cursorPos)
			}

			this.$refs.input.value = masked.value
			this.$refs.input.setSelectionRange(masked.cursorPosMasked, masked.cursorPosMasked)

			return {
				unmasked: masked.unmasked,
				masked: masked.value,
				isFullyMasked: masked.isFullyMasked,
			}
		},
		maskNumberGetMasked(value) {
			let masked;
			if (this.settings.maskThousandSeparator) {
				masked = Number(value).toLocaleString('de-ch', { minimumFractionDigits: this.maskNumberDigits, maximumFractionDigits: this.maskNumberDigits })
			}

			masked = masked.replaceAll(/\d/gi, '#')
			return masked
		},
		// filter out all chars except numbers and decimalSeparator
		maskNumberGetValue(masked) {

			if (!masked) return null
			if (masked == this.separatorDecimal) return `0${this.separatorDecimal}`
			if (masked == '-') return '-'
			let rgx = new RegExp(`^-?\\d+((’|')\\d*)*(${this.separatorDecimal}\\d+|${this.separatorDecimal})?`, 'gi')
			let value = masked.match(rgx)[0]

			value = value.replaceAll(/'|’/gi, '')
			if (masked[masked.length - 1] == this.separatorDecimal) {
				if (value[value.length - 1] != this.separatorDecimal) {
					value += this.separatorDecimal
				}
			}
			return value
		},
		maskGetMask(value, valueCursorPos) {

			value = value || ''
			let maskIndex = 0
			let valueIndex = 0
			let cursorPosMasked = 0
			let cursorPosRaw = 0
			let output = ''
			let outputUnmasked = ''

			function addCursorPos(valueCursorPos, valueIndex, valueLength) {
				// cursor ist at the end of value
				if (valueCursorPos == valueLength) {
					return valueCursorPos && valueCursorPos >= valueIndex ? true : false
				}
				// cursor is somewhere between
				return valueCursorPos && valueCursorPos > valueIndex ? true : false
			}

			while (valueIndex < value.length && maskIndex < this.dynamicMask.length) {
				let maskToken = this.dynamicMask[maskIndex] // token (not a placeholder)
				let placeholder = this.tokens[maskToken]
				let testChar = value[valueIndex]

				// mask placeholder with pattern
				if (placeholder) {
					if (placeholder.escape) {
						maskIndex++ // take the next mask char and treat it as char
						maskToken = this.dynamicMask[maskIndex]
						continue
					}
					if (placeholder.pattern.test(testChar)) {
						output += placeholder.transform ? placeholder.transform(testChar) : testChar
						outputUnmasked += testChar
						maskIndex++
						if (addCursorPos(valueCursorPos, valueIndex)) {
							cursorPosMasked++
							cursorPosRaw++
						}
					}
					valueIndex++ // inspect next value
					continue
				}

				// mask maskToken (not a placeholder with a pattern)
				if (testChar === maskToken) {
					output += maskToken // add to output
					// exception for mask == 'number: we need decimal separator
					if (this.settings.mask == 'number' && maskToken == this.separatorDecimal) {
						outputUnmasked += maskToken
					}
					valueIndex++
					if (addCursorPos(valueCursorPos, valueIndex, value.length)) {
						cursorPosMasked++
					}
					//cursorPosMasked++;
					maskIndex++
				} else {
					output += maskToken // add to output
					// exception for mask == 'number: we need decimal separator
					if (this.settings.mask == 'number' && maskToken == this.separatorDecimal) {
						outputUnmasked += maskToken
					}
					cursorPosMasked++
					maskIndex++
				}
			}

			// fix mask that ends with a char: (#)
			let restOutput = ''
			while (value && maskIndex < this.dynamicMask.length && this.settings.maskShow) {
				let cMask = this.dynamicMask[maskIndex]
				restOutput += cMask
				maskIndex++
			}

			return {
				value: output + restOutput,
				unmasked: outputUnmasked,
				isFullyMasked: maskIndex >= this.dynamicMask.length,
				cursorPosMasked,
				cursorPosRaw,
			}
		},
		setAutoWidthText(value) {
			this.$refs.autogrow.dataset.value = value
		},
		setLabelText(value) {
			this.$el.dataset.lbl = value || ''
		},
		onClear() {
			this.onInput(null)
			this.$emit('clear')
		},
		throttledEmit: utils.throttle(
			function (value) {
				this.emit(value)
			},
			200,
			{ leading: false, trailing: true },
		),
		emit(value) {
			this.$emit('input', value)
			this.$emit('change', value)
		},
		onMutationObserver() {
			this.maxWidthCorrection = 0
			if (this.$refs.beforeSlot) this.maxWidthCorrection += this.$refs.beforeSlot.offsetWidth
			if (this.$refs.beforeText) this.maxWidthCorrection += this.$refs.beforeText.offsetWidth
			if (this.$refs.afterSlot) this.maxWidthCorrection += this.$refs.afterSlot.offsetWidth
			if (this.$refs.afterClear) this.maxWidthCorrection += this.$refs.afterClear.offsetWidth
			if (this.$refs.afterText) this.maxWidthCorrection += this.$refs.afterText.offsetWidth
		},
	},
	computed: {
		settings() {
			let readonly = this.readonly === false ? false : true
			return {
				label: this.label,
				readonly: this.readonly,
				disabled: this.disabled,
				notification: this.notification,
				placeholder: this.placeholder,
				notificationIsError: this.notificationIsError,
				type: this.type,
				clearIcon: this.clearIcon,
				clearable: this.clearable, // value can be cleared be pressing x
				spellcheck: this.spellcheck,
				autocomplete: this.autocomplete,
				inputmode: this.inputmode,
				selectOnFocus: true,
				maxlength: this.maxlength,
				// mask:
				mask: this.mask,
				maskShow: this.maskShow,
				maskReturnValue: 'unmasked', // umasked || masked
				maskNumberDigits: this.maskNumberDigits,
				maskThousandSeparator: true,
				before: null, // text inserted before input
				after: null, // text inserted after inptut
				formatTo: null, // string or function to format value before emit
				tabindex: this.tabindex,
				textAlign: this.textAlign,
				throttled: true,
				blurOnEnter: false,
				width: this.width,
				minWidth: this.minWidth,
				...this.options,
			}
		},
		placeholderText() {
			if (this.settings.placeholder) return this.settings.placeholder
			if (this.settings.maskShow) return this.settings.mask
			return null
		},

		inputName() {
			if (this.settings.name) return this.settings.name
			if (this.settings.autocomplete != 'new-password') return this.settings.label
			return this.inputId
		},
		inputId() {
			return this.options.id ? this.options.id : null
		},
		inputType() {
			if (this.settings.type == 'password' && this.pwMask == false) {
				return 'text'
			}
			return this.settings.type
		},
		inputmodeValue() {
			// selects keyboard
			if (this.settings.inputmode) return this.settings.inputmode
			if (this.settings.mask == 'number') return 'numeric'
			return null
		},
		inputStyle() {
			let textAlign = this.settings.mask == 'number' ? 'right' : this.settings.textAlign
			let fontSize = this.settings.fontSize ? this.settings.fontSize : '1rem'
			let lineHeight = this.settings.lineHeight ? this.settings.lineHeight : '20px'
			return { textAlign, fontSize }
		},
		autoGrowStyle() {
			return { maxWidth: `calc(100% - ${this.maxWidthCorrection}px)` }
		},
		componentStyle() {
			let style = {}
			if (this.settings.minWidth) style.minWidth = this.settings.minWidth
			if (this.settings.width) style.width = this.settings.width
			return style
		},

	},
	watch: {
		value: {
			immediate: false,
			handler: function (value) {
				if (this.componentValue === value) return
				this.onInput(value, false)
			},
		},
		settings: {
			deep: true,
			handler: function (val) {
				this.setLabelText(val.label)
			},
		},
	},
	created() {
		// set separatorDecimal from intl
		let formatter = new Intl.NumberFormat('de-CH', {})
		let parts = formatter.formatToParts(1000.1)
		this.separatorDecimal = parts[3].value
		this.dynamicMask = this.settings.mask
		this.componentValue = null
	},
	mounted() {
		this.observer = new MutationObserver(this.onMutationObserver);
		this.observer.observe(this.$el, {
			childList: true,
			subtree: true
		});
		this.onMutationObserver() // call it for the first time
		this.setLabelText(this.settings.label)
		this.onInput(this.value, false)
	},
	beforeDestroy() {
		this.observer.disconnect();
	}
}
</script>

<style scoped>
/* click events are eaten by disabled inputs this enables to catch a click event on parent element*/
input[disabled] {
	pointer-events: none;
}

input {
	outline: none;
	border: 0;
	flex: 1 1 auto;
	line-height: 20px;
	max-width: 100%;
	min-width: 100%;
	width: 100%;
	position: relative;
	white-space: nowrap;
	text-overflow: ellipsis;
	background-color: transparent;
	font-size: 1rem;
}

input:disabled {
	background-color: inherit;
	border: 0;
}

input::selection {
	background: rgb(218, 218, 218);
}

.Input {
	display: inline-flex;
	flex-direction: column;
	position: relative;
	align-items: flex-start;
	text-align: left;
	font-size: 1rem;
}

.Input::before {
	content: attr(data-lbl) ' ';
	font-size: 12px;
	max-width: 100%;
	top: 6px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	pointer-events: none;
	color: rgba(0, 0, 0, 0.54);
}

.autogrow {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 40px;
	max-width: 100%;
	width: 100%;
	position: relative;

}

.autogrow::after {
	font: inherit;
	padding: 0 3px;
	margin: 0;
	resize: none;
	background: none;
	appearance: none;
	border: none;
	content: attr(data-value) ' ';
	visibility: hidden;
	white-space: nowrap;
	height: 1px;
	margin-top: -3px;
}

.slots {
	display: inline-flex;
	align-items: center;
	max-width: 100%;
	min-height: 100%;
}

.before {
	display: inline-flex;
	align-items: center;
	max-height: 100%;
	padding-right: 5px;
}

.Input.basic::after {
	display: none;
}

.Input.basic .activation::before,
.Input.basic .activation::after,
.Input.basic .notification {
	display: none;
}

.Input.noLabel::before {
	display: none;
}

.Input.noLabel .activation input {
	padding-top: 0;
}

.Input.basic .activation input {
	padding: 0;
}

.Input.basic .autogrow {
	height: unset;
}

.Input.noNotification .notification,
.Input.basic .notification {
	display: none;
}

.activation {
	display: flex;
	max-width: 100%;
	width: 100%;
	position: relative;
	align-items: center;
	padding: 0 3px;
}

.activation>.before,
.activation>.after {
	align-self: center;
}

.beforeText {
	display: flex;
	align-self: center;
	margin-right: 5px;
}

.afterText,
.afterClear {
	display: flex;
	align-self: center;
	margin-left: 5px;
}

.activation::before {
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: 100%;
	border-style: solid;
	border-width: 1px 0 0 0;
	border-color: #619cca;
	z-index: 1;
	white-space: nowrap;
}

.Input.readonly:not(.datePickerEnabled) .activation::before {
	border-color: rgba(0, 0, 0, 0.42);
}

.activation::after {
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: 100%;
	transform: scaleX(0);
	border-style: solid;
	border-width: 1px 0 1px 0;
	border-color: #619cca;
}

.Input.readonly:not(.datePickerEnabled) .activation::after {
	border-color: rgba(0, 0, 0, 0.42);
}

.Input.focus:not(.readonly) .activation::after {
	transform: scaleX(1);
}

.Input.datePickerEnabled.focus .activation::after {
	transform: scaleX(1);
}

.notification {
	font-size: 12px;
	line-height: 12px;
	margin-top: 4px;
	height: 12px;
}

.notification.error {
	color: red;
}

.pwMask {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 16px;
}

.pwMask:hover {
	transform: scale(1.3);
}

.Input.textAlignRight input {
	text-align: right;
}

/* selection color */
input::-moz-selection {
	/* Firefox */
	background: rgb(218, 218, 218);
}

.Input:not(.readonly) input::-moz-selection {
	/* Firefox */
	background: #cbe2f7;
}

.Input:not(.readonly) input::selection {
	background: #cbe2f7;
}

.clearIcon {
	font-size: 11px;
	margin: 0 3px;
	cursor: pointer;
	/* position: absolute;
	right: 0;
	top: 12px; */
}

.gridFilter .Icon {
	margin-right: 5px;
}
</style>
