<template>
   <div class="CreditTransactionsDialog">
      <div class="header">
         <div v-for="(header, index) in transaction.header"
            :key="index"
            class="header">{{ header }}</div>
      </div>
      <div class="payment">
         <div class="title">Zahlung</div>
         <div class="date">Datum: {{ toEuDate(transaction.date) }}</div>
         <div class="amount">Betrag: {{
            toFormatNumber(transaction.amount) }}
         </div>
      </div>
      <div class="creditorList">
         <div class="title">Auftraggeber</div>
         <div v-for="(creditor, index) in transaction.creditor"
            :key="index">{{ creditor }}</div>
      </div>

      <div class="reference">
         <div class="title">Zahlungsreferenz</div>
         <div v-if="transaction.billNo"
            class="billNo">Rechnungsnummer: {{ transaction.billNo }}</div>
         <div v-if="transaction.customerId"
            class="customerId">Kundennummer: {{ transaction.customerId }}</div>
         <div v-if="transaction.qrCode"
            class="qrCode">QrCode: {{ transaction.qrCode }}</div>
         <div v-for="(reference, index) in transaction.reference"
            :key="index">{{ reference }}</div>
      </div>
      <div class="comment">
         <div class="title">Kommentar</div>
         <Textarea :value="transaction.comment"
            @input="onTransactionCommentInput"
            @blur="onTransactionCommentBlur" />
      </div>
      <div class="status">
         <div class="title">Status</div>
         <div class="typeRow">
            <CheckBox class="small"
               :value="!transaction.creditType || transaction.creditType == 'billPayment'"
               @input="onTransactionDialogCheckBox('billPayment', $event)">Rechnung</CheckBox>
            <CheckBox class="small"
               :value="transaction.creditType == 'refund'"
               @input="onTransactionDialogCheckBox('refund', $event)">Gutschrift</CheckBox>
            <CheckBox class="small"
               :value="transaction.creditType == 'other'"
               @input="onTransactionDialogCheckBox('other', $event)">Andere</CheckBox>
         </div>
         <template v-if="!transaction.creditType || transaction.creditType == 'billPayment'">
            <div v-if="this.transaction.bills && this.transaction.bills.length == 0"
               class="bar info red  noMatch">
               Zahlungsreferenz nicht gefunden
            </div>
            <div v-if="transaction.bills"
               class="bills">
               <div v-for="(bill, index) in transaction.bills"
                  :key="index"
                  class="bill">
                  <div class="billNo">{{ bill.billNo }}</div>
                  <div class="customerName">{{ bill.customerName }}</div>
                  <div class="date">{{ toEuDateShort(bill.date) }}</div>
                  <div class="amount">
                     <InputBasic :value="bill.amount"
                        @input="onAmountInput(bill, $event)"
                        @blur="onAmountBlur(bill)" />
                  </div>
                  <div class="delete"
                     @click="onTransactionDialogDeleteBill(bill)">
                     <Icon :icon="$static.iconDelete" />
                  </div>
               </div>
            </div>
            <div class="addRow">
               <div v-if="addBillDialog"
                  class="addDlg">
                  <div class="list">
                     <div v-for="(bill, index) in addSearchResult"
                        :key="index"
                        class="bill"
                        @click="onTransactionDialogAddBill(bill)">
                        <div class="billNo">{{ bill.billNo }}</div>
                        <div class="customerName">{{ bill.customerName }}</div>
                        <div class="date">{{ toEuDateShort(bill.date) }}</div>
                        <div class="amount">{{ toFormatNumber(bill.value) }}</div>
                     </div>
                  </div>
                  <InputBasic ref="AddSearchText"
                     placeholder="Rechnungsnummer Betrag Kunde"
                     v-model="addSearchText"
                     @input="onTransactionDialogAddBillSearch"
                     @escape="onTransactionDialogAddBillEscape"
                     @hook:mounted="onTransactionDialogAddBillMounted" />
               </div>
               <div v-else-if="showTransactionAddBtn"
                  class="btnAdd"
                  @click="onShowTransactionDialogAddBill">
                  <Icon :icon="$static.iconAdd" />
               </div>
            </div>
         </template>
      </div>
      <div class="actionRow">
         <Button v-if="hasChanges"
            class="blue"
            @click="$emit('save', transaction)">Speichern</Button>
         <Button v-else
            @click="$emit('close')">
            Schliessen
         </Button>
      </div>
   </div>
</template>

<script>
import api from '@components/Portal/api'
import { Day } from '@SyoLab/date-time'
import CheckBox from '@components/Form/CheckBox.vue';
import Icon from "@icons/Icon.vue"
import { Add, Cancel } from "@icons/appFabric/icons"
import InputBasic from '@components/Form/InputBasic.vue';
import { throttleAsync } from '@SyoLab/utils'
import Button from '@components/Form/Button.vue';
import Textarea from '@components/Form/Textarea.vue';
export default {
   name: 'CreditTransactionsDialog',
   components: {
      Icon, InputBasic, Button, CheckBox, Textarea
   },
   props: {
      transaction: { required: true },
   },
   data() {
      return {
         addBillDialog: false,
         addSearchText: null,
         addSearchResult: [],
         hasChanges: false,
         showTransactionAddBtn: false,
      }
   },
   static: {
      iconAdd: Add,
      iconDelete: Cancel,
   },
   methods: {
      toFormatNumber(value) {
         if (!value) return null
         return Number(value).toLocaleString('de-ch', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
         })
      },
      toEuDate(date) {
         if (!date) return null
         return new Day(date).toEuDate()
      },
      toEuDateShort(date) {
         if (!date) return null
         return new Day(date).toFormat('dd.MM.yy')
      },
      onTransactionDialogCheckBox(value, checked) {
         if (!checked) return
         this.hasChanges = true
         this.$set(this.transaction, 'creditType', value)
         this.setShowTransactionAddBtn()
      },
      onTransactionDialogAddBillSearch: throttleAsync(async function () {
         if (!this.addSearchText) {
            this.addSearchResult = []
            return
         }
         let res = await api.post('finances/bills/search', {
            searchText: this.addSearchText,
            matched: { $ne: true }
         })
         this.addSearchResult = res
      }),
      onShowTransactionDialogAddBill() {
         setTimeout(() => {
            this.addBillDialog = true
            this.hasChanges = true
         }, 100)

      },
      onTransactionDialogAddBillEscape() {
         setTimeout(() => {
            this.addSearchResult = []
         }, 300);

      },
      onTransactionDialogAddBill(bill) {
         if (!this.transaction.bills) this.$set(this.transaction, 'bills', [])
         this.transaction.bills.push({
            billNo: bill.billNo,
            amount: Number(bill.value),
            date: bill.date,
            customerName: bill.customerName,
            customerId: bill.customerId,
         })
         this.transaction.matched = true
         this.transaction.customerId = bill.customerId
         this.transaction.customerName = bill.customerName
         this.hasChanges = true
         setTimeout(() => {
            this.addBillDialog = false
         }, 100);

      },
      onTransactionDialogDeleteBill(bill) {
         setTimeout(() => {
            let idx = this.transaction.bills.findIndex(b => b.billNo == bill.billNo)
            this.transaction.bills.splice(idx, 1)
            this.transaction.matched = false
            this.hasChanges = true
            if (this.transaction.bills.length == 0) {
               this.$delete(this.transaction, 'bills')
            }
         }, 100);

      },
      onTransactionDialogAddBillMounted(e) {
         this.$refs.AddSearchText.focus()
      },
      onAmountInput(bill, value) {
         if (isNaN(value)) return
         this.hasChanges = true
         bill.amount = value
         this.hasChanges = true
      },
      onAmountBlur(bill) {
         bill.amount = Number(bill.amount)
      },
      setShowTransactionAddBtn() {

         if (this.transaction.creditType && this.transaction.creditType != 'billPayment') {
            this.showTransactionAddBtn = false
            return
         }
         if (!this.transaction.bills) {
            this.showTransactionAddBtn = true
            return
         }
         let billsTotal = this.transaction.bills.reduce((acc, bill) => {
            return acc + Number(bill.amount)
         }, 0)
         let diff = Number(this.transaction.amount) - billsTotal

         this.showTransactionAddBtn = diff > 1 || diff < -1 ? true : false

      },
      onTransactionCommentInput(value) {
         if (this.transaction.comment == undefined) this.$set(this.transaction, 'comment', '')
         this.transaction.comment = value
         this.hasChanges = true
      },
      onTransactionCommentBlur() {
         if (this.transaction.comment) {
            this.transaction.comment = this.transaction.comment.trim()
         }
      }
   },
   computed: {
      showWarningNoMatch() {
         if (this.transaction.creditType && this.transaction.creditType != 'billPayment') return false
         return !this.transaction?.matched
      },
   },
   watch: {
      addBillDialog(value) {
         this.addSearchText = null
      },
      'transaction.bills': {
         deep: true,
         immediate: true,
         handler() {
            this.setShowTransactionAddBtn()
            this.transaction.matched = !this.showTransactionAddBtn
         }
      }
   },

}
</script>

<style scoped>
.CreditTransactionsDialog {
   display: flex;
   flex-direction: column;
   row-gap: 15px;
   position: relative;
   min-width: 425px;
}

.header {
   display: flex;
   flex-direction: column;
}

.title {
   font-size: 20px;
   font-weight: 300;
}

.noMatch {
   padding-top: 1px;
   padding-bottom: 1px;
   padding-left: 6px;
   margin: 3px 0;
}

.typeRow {
   display: flex;
   justify-content: space-between;
   padding: 5px 0;
}

.addRow {
   display: flex;

   .btnAdd {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: whitesmoke;
      cursor: pointer;
      margin-top: 6px;
      font-size: 14px;
      padding: 5px;

      &:hover {
         background-color: var(--activeHover);
      }
   }

   .addDlg {
      display: flex;
      width: 100%;
      padding-top: 3px;
      position: relative;

      .Input {
         width: 100%;
         border: 1px solid grey;
         padding: 1px;
         border-radius: 3px;
      }

      .list {
         position: absolute;
         bottom: 100%;
         left: 0;
         width: 100%;
         display: flex;
         flex-direction: column;
         background-color: white;
         border: 1px solid grey;

         .bill {
            cursor: pointer;

            &:hover {
               background-color: whitesmoke;
            }
         }
      }

   }
}

.bill {
   display: flex;
   gap: 5px;
   position: relative;


   .billNo {
      min-width: 60px;
      padding-left: 3px;
   }

   .customerName {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
   }

   .date {
      width: 65px;
   }

   .amount {
      min-width: 77px;
      text-align: right;
      padding-right: 3px;

      .Input {
         width: 100%;
      }
   }

   .amount:deep(Input) {
      text-align: right;
   }

   .delete {
      display: flex;
      align-items: center;
      cursor: pointer;
   }

}

.comment {
   .Textarea {
      width: 100%;
      padding: 5px;
      background-color: var(--activeHover);
   }
}


.actionRow {
   display: flex;
   justify-content: center;
}
</style>