<template>
   <div class="SubscriptionComment">
      <label>{{ label }}</label>
      <Textarea :placeholder="placeholder"
         v-model="subscription.comment" />
      <div class="passwords">
         <InputBasic class="url"
            placeholder="Url"
            width="175px" />
         <InputBasic class="username"
            placeholder="Username" />
         <InputBasic class="password"
            placeholder="Password" />
      </div>

   </div>
</template>

<script>
import InputBasic from '@components/Form/InputBasic.vue';
import Textarea from '@components/Form/Textarea.vue';
export default {
   name: 'SubscriptionComment',
   components: { InputBasic, Textarea },
   setup(props, context) { },
   props: {
      subscription: { required: true },
      label: { required: true },
      placeholder: { required: false },
   },
   data() {
      return {}
   },
   methods: {},
   computed: {},
}
</script>

<style scoped>
.SubscriptionComment {
   display: flex;
   flex-direction: column;
   row-gap: 3px;
}

.passwords {
   display: flex;
}

.Input.url {
   width: 50%;
}

.Input.username {
   width: 30%;
}

.Input.password {
   width: 20%;
}

.Textarea {
   background-color: var(--activeHover);
   min-height: 25px;
   width: 100%;
   padding: 5px;
}
</style>